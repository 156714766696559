<template>
    <a href="#" @click.prevent="doClick" class="evt-card relative br-16 flx column gap-16 transition-sm"
        :class="isActive ? 'list-is-active' : ''">
        <div v-if="computedFullyBooked && !event.repeat_at && event.attendance_limit === event.limit_count"
            class="absolute bked">
            <event-type :eventType="'Fully booked'" />
        </div>
        <div class="evt-card-wrapper flx column gap-4">
            <div class="bg-img relative"
                :style="{ backgroundImage: 'url(' + s3bucket + '/' + JSON.parse(event.gallery)[0] + ')' }">
                <div v-if="bookingStatus && is_climber" class="absolute status-wrapper">

                    <event-type :eventType="event.event_type" :class="'bg-white mr-4 mb-4'" />
                    <booking-status :status="bookingStatus" />
                </div>
            </div>
            <div class="foot flx column gap-4">
                <div class="flx jc-sb">
                    <h4 class="fs-09">
                        <div class="wrap-text wrap-line-1">
                            {{ event.event_name }}
                        </div>
                    </h4>

                </div>
                <div class="flx gap-24">
                    <div class="flx column">
                        <div v-if="!event.repeat_at" class="label flx gap-4 ai-c">
                            Date & Time
                        </div>
                        <div v-else class="label flx gap-4 ai-c">
                            <span class="wrap-text wrap-line-1">Duration</span>
                        </div>
                        <span class="wrap-text wrap-line-1" title="Jun 20">{{ !event.repeat_at ?
                            `${format_date_short3(event.start_date)} ${format_time_short(event.start_date)}` :
                            event.event_duration.replace('[', '').replace(']', '').replace(/"/g, '') }}</span>
                    </div>
                    <div class="flx column">
                        <div class="label flx gap-4 ai-c">
                            <!-- <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 20.101 19.821">
                                    <path d="M-3280.074-712.862a22.722,22.722,0,0,1-4.877-3.317,11.435,11.435,0,0,1-3.8-6.158,8.244,8.244,0,0,1,1.584-6.6c1.235-1.576,3.682-3.454,8.318-3.454s7.083,1.878,8.319,3.454a8.245,8.245,0,0,1,1.584,6.6,11.436,11.436,0,0,1-3.8,6.158,22.725,22.725,0,0,1-4.878,3.317,2.694,2.694,0,0,1-1.222.3A2.7,2.7,0,0,1-3280.074-712.862Zm-5.778-15.036a6.543,6.543,0,0,0-1.258,5.237,9.809,9.809,0,0,0,3.287,5.243,21.039,21.039,0,0,0,4.511,3.065,1.013,1.013,0,0,0,.461.112,1.015,1.015,0,0,0,.462-.112,21.039,21.039,0,0,0,4.511-3.065,9.818,9.818,0,0,0,3.287-5.243,6.547,6.547,0,0,0-1.258-5.237c-1.443-1.84-3.864-2.813-7-2.813S-3284.41-729.739-3285.852-727.9Zm3.651,3.886a3.353,3.353,0,0,1,3.349-3.35,3.353,3.353,0,0,1,3.35,3.35,3.353,3.353,0,0,1-3.35,3.349A3.352,3.352,0,0,1-3282.2-724.012Zm1.675,0a1.676,1.676,0,0,0,1.674,1.675,1.676,1.676,0,0,0,1.675-1.675,1.677,1.677,0,0,0-1.675-1.675A1.676,1.676,0,0,0-3280.526-724.012Z" transform="translate(3288.902 732.387)" fill="#a7a7a7"/>
                                </svg> -->
                            <span>Location</span>
                        </div>
                        <span class="wrap-text wrap-line-1">{{ event.address.length > 20 ? event.address.slice(0, 17) +
                            '...' : event.address }}</span>
                    </div>
                </div>
                <div class="flx">
                    <div v-if="event.event_type === 'public'" class="fs-09 no-wrap"><span>From </span><strong>{{
                        formatAmount(computedPriceRangePublic) }}</strong></div>
                    <div v-else class="fs-09 no-wrap"><span class="gray">From </span><strong>{{
                            formatAmount(computedPriceRange) }}</strong></div>
                </div>
            </div>
        </div>
    </a>
</template>

<script>
import userRolesMixin from '@/mixins/userRolesMixin';
import formatDateTime from '@/mixins/formatDateTime';
import { mapState } from 'vuex'
import BookingStatus from './BookingStatus.vue';
import EventType from './EventType.vue';
import amountFormatter from '@/mixins/amountFormatter';
export default {
    components: { BookingStatus, EventType },
    name: 'EventList',
    mixins: [formatDateTime, userRolesMixin, amountFormatter],
    props: {
        event: Object,
        event_id: Number,
        redirect: Boolean
    },
    computed: {
        ...mapState({
            s3bucket: (state) => state.s3bucket,
            bookings: (state) => state.bookings
        }),
        eventType() {
            const today = new Date()
            const eventDate = new Date(this.event.end_date)
            return today > eventDate ? 'past' : 'registered'
        },
        bookingStatus() {
            return this.is_climber && this.bookings ? this.bookings.find(data => data.id === this.event.id) : null
        },
        computedPriceRange() {
            if (this.event && this.event.price && JSON.parse(this.event.price).length) {
                const priceArray = JSON.parse(this.event.price)
                //return the smallest price in the priceArray check both .price and .priceh
                // const lastPrice = priceArray[priceArray.length - 1]
                // const smallestPrice = Math.min(lastPrice.price, lastPrice.priceh)
                // return smallestPrice
                const prices = priceArray.map(item => [item.price, item.priceh])
                    .flat()
                    .filter(item => item !== null && item !== undefined)
                return Math.min(...prices)

                // return priceArray[priceArray.length - 1].price

                

            }
            else
                return null
        },
        //this is another function to get the computed price range for public event with facts that some events still have no json price
        computedPriceRangePublic() {
            if (this.event && this.event.price) {
                if (JSON.parse(this.event.price).length) {
                    const priceArray = JSON.parse(this.event.price)
                    // return priceArray[priceArray.length - 1].price
                    const prices = priceArray.map(item => [item.price, item.priceh])
                    .flat()
                    .filter(item => item !== null && item !== undefined)
                    return Math.min(...prices)
                } else {
                    return Number(this.event.price)
                }

            }
            else
                return null
        },
        computedFullyBooked() {
            const userBookedEvent = this.bookings ? this.bookings.find(booked => booked.event_id === this.event.id || this.event.event_id) : null
            return userBookedEvent ? false : true
        },
        isActive() {
            if (!this.is_climber) {
                return this.$route.query.current && this.$route.query.current == this.event.id ? true : false
            } else {
                return this.bookingStatus && this.bookingStatus.id == this.$route.query.status ? true : false
            }
        },
    },
    methods: {
        doClick() {
            if (this.redirect) {
                let route_name = 'UpcomingEvents'
                let id = this.event.id
                let status = 'event'
                if (this.is_climber) {
                    route_name = 'MyEvents'
                    id = this.event.event_id
                    status = this.bookingStatus.id
                } else {
                    route_name = 'UpcomingEvents';
                }
                if (this.$route.name === route_name && this.$route.query.type !== 'all') {
                    this.$router.push({ name: route_name, query: { type: this.eventType, current: id, origin: this.$route.name, status: status } })
                } else {
                    this.$router.push({ name: route_name, query: { current: id, origin: this.$route.name, status: status } })
                }
            } else {
                this.$emit('open-modal', this.event)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.evt-card {
    flex-basis: calc(50.33% - 16px) !important;
    min-height: 300px;

    .bg-img {
        height: 64%;
        border-radius: 8px;
    }

    .label,
    span {
        font-size: 0.8rem;
    }

    .label {
        font-size: 0.9rem;
        color: var(--gray);
    }

    // &:hover:not(.list-is-active) {
    //     //box-shadow: 0 4px 12px 0 rgba(15,41,77,.08);
    // }
}

.evt-card-wrapper {
    height: 100%;
}

.foot {
    height: 100%;
}

.status-wrapper {
    inset: 8px auto auto 8px;
}

.bked {
    z-index: 2;
    inset: 8px 8px auto auto;

    .evt-status {
        background-color: var(--danger);
        color: var(--white);
    }
}
</style>