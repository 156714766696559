export default {
    mounted() {
        const google = window.google
        const autocomplete = new google.maps.places.Autocomplete(
            this.$refs['address'],
            {
                bounds: new google.maps.LatLngBounds(
                    new google.maps.LatLng(5.564836107372255, -0.3288140182577681)
                )
            }
        );
        autocomplete.addListener('place_changed', () => {
            let address = autocomplete.getPlace();

             // Extract the full address from the address_components array
             let fullAddress = address.formatted_address || this.getFullAddress(address);


            this.form.address = fullAddress
            this.form.latitude = address.geometry.location.lat()
            this.form.longitude = address.geometry.location.lng()

             // Generate Google Maps link
             const googleMapsLink = this.generateGoogleMapsLink(this.form.latitude, this.form.longitude);
             console.log(googleMapsLink);  // You can also display this in the UI or store it
        })
    },

    methods: {
        getFullAddress(place) {
            if (!place.address_components) {
                return '';
            }

            // Construct the full address from the address components
            let address = place.address_components.map(component => component.long_name).join(', ');
            return address;
        },

        generateGoogleMapsLink(lat, lng) {
            // Generates the Google Maps link for the exact location
            return `https://www.google.com/maps?q=${lat},${lng}`;
        }
    }
}