<template>
    <form @submit.prevent="" class="flx column gap-24" id="cal_stepper_1_form">
        <div class="form-row column">
            <label for="event_name">Event name</label>
            <div class="input-wrapper">
                <input v-model="form.event_name" class="br-16 w-100" type="text" id="event_name" name="event_name"  :class="[{ 'error-border': validation.errors.event_name }, input2 ? 'form-control2' : 'form-control']" placeholder="Enter your event name" />
            </div>
            <span class="input-error" v-if="validation.error && validation.errors.event_name">
                {{ validation.errors.event_name[0] }}
            </span>
        </div>
        <div class="form-row flx column jc-sb">
            <div>
                Duration type
            </div>
            <div class="flx gap-8 ai-c">
                <label for="fhday" class="flx gap-4" data-type="input-wapper">
                    <input v-model="form.duration_type" type="radio" id="fhday" value="multi">
                    <span>
                        Multi Duration
                    </span>
                </label>
                <label for="custome" class="flx gap-4" data-type="input-wapper">
                    <input v-model="form.duration_type" type="radio" id="custome" value="single">
                    <span>
                       Single Duration
                    </span>
                </label>
            </div>
        </div>
        <div class="form-row column" v-if="form.duration_type === 'single'">
            <label for="event_duration">Duration</label>
            <div class="input-wrapper">
                <input v-model="form.event_duration[0]" @input="sliceInputsingle" class="br-16 w-100" type="text" maxlength="10" id="event_duration" name="event_duration"  :class="[{ 'error-border': validation.errors.event_duration }, input2 ? 'form-control2' : 'form-control']" placeholder="E.g 4 hours, half day, 2 days..." />
            </div>
            <span class="input-error" v-if="validation.error && validation.errors.event_duration">
                {{ validation.errors.event_duration[0] }}
            </span>
        </div>
        <div class="form-row column" v-if="form.duration_type === 'multi'">
            <label for="event_duration">Duration</label>
            <div class="input-wrapper">
                <input v-model="form.event_duration[0]" @input="sliceInput" class="br-16 w-100" type="text" maxlength="10" id="event_duration" name="event_duration"  :class="[{ 'error-border': validation.errors.event_duration }, input2 ? 'form-control2' : 'form-control']" placeholder="Duration 1-E.g 4 hours, half day, 2 days..." />
            </div>
            <div class="input-wrapper">
                <input v-model="form.event_duration[1]" @input="sliceInput" class="br-16 w-100" type="text" maxlength="10" id="event_duration" name="event_duration"  :class="[{ 'error-border': validation.errors.event_duration }, input2 ? 'form-control2' : 'form-control']" placeholder="Duration 2-E.g 4 hours, half day, 2 days..." />
            </div>
            <span class="input-error" v-if="validation.error && validation.errors.event_duration">
                {{ validation.errors.event_duration[0] }}
            </span>
        </div>
        <div class="form-row flx jc-sb">
            <div>
                Repeat this event?
            </div>
            <div class="flx gap-8 ai-c">
                <label for="repeat_yes" class="flx gap-4" data-type="input-wapper">
                    <input v-model="form.repeat" type="radio" id="repeat_yes" :value="true">
                    <span>
                        Yes
                    </span>
                </label>
                <label for="repeat_no" class="flx gap-4" data-type="input-wapper">
                    <input v-model="form.repeat" type="radio" id="repeat_no" :value="false">
                    <span>
                        No
                    </span>
                </label>
            </div>
        </div>
        <div class="form-row column">
            <label for="start_date">{{ form.repeat ? 'Start date' : 'Select date' }}</label>
            <div class="input-wrapper">
                <input v-model="form.start_date" class="br-16 w-100" type="datetime-local" id="start_date" name="start_date" :class="[{'error-border': validation.errors.start_date }, input2 ? 'form-control2' : 'form-control']" />
            </div>
            <span class="input-error" v-if="validation.error && validation.errors.start_date">
                {{ validation.errors.start_date[0] }}
            </span>
        </div>
        <div v-if="form.repeat" class="form-row column">
            <label for="end_date">End date</label>
            <div class="input-wrapper">
                <input v-model="form.end_date" class="br-16 w-100" type="datetime-local" id="end_date" name="end_date" :class="[{'error-border': validation.errors.end_date }, input2 ? 'form-control2' : 'form-control']" />
            </div>
            <span class="input-error" v-if="validation.error && validation.errors.end_date">
                {{ validation.errors.end_date[0] }}
            </span>
        </div>
        <div v-if="form.repeat" class="form-row column">
            <label for="repeat_at">Frequency</label>
            <div class="input-wrapper">
                <select v-model="form.repeat_at" name="repeat_at" id="repeat_at" class="w-100 br-16" :class="input2 ? 'form-control2' : 'form-control'">
                    <option v-for="(repeat, index) in repeat_at" :key="index" :value="repeat.value">{{ repeat.label }}</option>
                </select>
            </div>
        </div>
        <error-display-card v-if="validation.error" :errors="validation.errors"/>
        <div class="calendar-btn-wrapper">
            <button @click.prevent="nextPage" class="button-primary btn-lg w-100" :class="{ 'button-disabled2' : !user.details_submitted && !user.payouts_enabled }" :disabled="!user.details_submitted && !user.payouts_enabled ? true : false">
                Next
            </button>
            <div class="mt-8 fs-09 text-center" v-if="!user.details_submitted && !user.payouts_enabled">
                In order to get started with listing events, accepting payment and receiving payouts, you will need to setup your payout account. <a href="#" @click.prevent="goToPayout" class="a-link ft-secondary">Setup account now.</a>
            </div>
        </div>
    </form>
</template>

<script>
import inputValidationMixin from '@/mixins/inputValidation'
import ErrorDisplayCard from './ErrorDisplayCard.vue'
import { mapState } from 'vuex'
export default {
    components: { ErrorDisplayCard },
    name: 'CalendarStepper1',
    mixins: [inputValidationMixin],
    computed: {
        ...mapState({
            repeat_at: (state) => state.data.repeat_at,
            updateModal: (state) => state.forms.active
        })
    },
    props: {
        newEvent: Object,
        input2: Boolean,
        editMode: String,
        user: Object
    },
    data() {
        return {
            form: {
                event_name: '',
                event_duration: [],
                start_date: '',
                end_date: '',
                repeat: false,
                duration_type:'multi',
                repeat_at: 'daily'
            }
        }
    },
    watch: {
        'form.duration_type'(newValue) {
            if(newValue === 'single') {
                this.form.event_duration.splice(1, 1)
            }
        },
        'form.event_duration'(newVal) {
            if(newVal === 'fhday') {
                this.form.duration_type = true
            }
        }
    },
    methods: {
        sliceInputSingle() {
            let input = this.form.event_duration
            if (input.length > 10) {
                this.form.event_duration = input.slice(0, 10);
            }
        },
        async nextPage() {
            this.validation.error ? this.clearErrs() : ''
            let errors = {}
            if(this.form.event_name == '' || this.form.start_date == '' || (this.form.repeat && this.form.end_date == '') || this.form.event_duration == '' || (this.form.duration_type === 'single' && !this.form.event_duration[0]) || (this.form.duration_type === 'multi' && (!this.form.event_duration[0] || !this.form.event_duration[1]))) {
                if(this.form.event_name == '') {
                    errors.event_name = ['The event name field is required.']
                }
                if(this.form.start_date == '') {
                    errors.start_date = ['Please select a date.']
                }
                if(this.form.repeat && this.form.end_date == '') {
                    errors.end_date = ['Please select an end date.']
                }
                if(this.form.duration_type == 'multi' && (!this.form.event_duration[0] || !this.form.event_duration[1])) {
                    errors.event_duration = ['Please enter all event duration fields.']
                }else{
                    errors.event_duration = ['Please enter event duration.']
                }
                // if(this.form.event_duration == '') {
                    
                // }
                this.showErr(errors)
            }else {
                this.editMode === 'event_edit' ? await this.$store.commit('updateTempStorage1', this.form) : await this.$store.commit('saveEventForm1', this.form)
                this.$router.push({ query: { stepper: '2', current: this.$route.query.current, origin: this.$route.query.origin }})
            }
        },
        presetForm() {
            this.newEvent && this.newEvent.event_name ? this.form.event_name = this.newEvent.event_name : ''
            

            if(this.newEvent && this.newEvent.event_duration && this.newEvent.event_duration.length == 2) {
                this.newEvent && this.newEvent.event_duration ? this.form.event_duration = this.newEvent.event_duration : ''
                this.form.duration_type = 'multi'
            } else if (this.newEvent && this.newEvent.event_duration && this.newEvent.event_duration.length == 1) {
               this.newEvent && this.newEvent.event_duration ? this.form.event_duration = this.newEvent.event_duration : ''
               this.form.duration_type = 'single'
               this.form.event_duration[0] = this.newEvent.event_duration[0]
            }

            this.newEvent && this.newEvent.start_date ? this.form.start_date = this.newEvent.start_date : ''
            this.newEvent && this.newEvent.end_date ? this.form.end_date = this.newEvent.end_date : ''
            this.newEvent && this.newEvent.repeat ? this.form.repeat = this.newEvent.repeat : this.form.repeat = false
            this.newEvent && this.newEvent.repeat_at ? this.form.repeat_at = this.newEvent.repeat_at : ''
            if(this.editMode === 'event_edit' && this.newEvent.repeat_at) {
                this.form.repeat = true
            }
            console.log(this.newEvent.event_duration)
            //check if this.editMode === 'event_edit' , if yes then set then check if the value of this.newEvent.event_duration is an array of length 2, if yes then set the value of this.form.duration_type to 'multi' else set it to 'single'

            if(this.editMode === 'event_edit' && this.newEvent.event_duration && this.getdurations(this.newEvent.event_duration).length == 2) {

                console.log('here')
                this.form.duration_type = 'multi'
                //set the values of this.form.event_duration to the values of this.newEvent.event_duration
                this.form.event_duration = this.getdurations(this.newEvent.event_duration)
            } else if (this.editMode === 'event_edit' && this.getdurations(this.newEvent.event_duration).length == 1) {
                this.form.duration_type = 'single'
                this.form.event_duration[0] = this.getdurations(this.newEvent.event_duration)[0]
            }
        },
        getdurations(durations) {
            if(/^\[.*\]$/.test(durations)){
                durations = durations.replace('[', '').replace(']', '').replace(/"/g, '').split(',')
                // durationarray = [this.event.event_duration.replace('[', '').replace(']', '').replace('"', '').replace(/"/g, '')]
            }else{
                durations = [durations]
            }
            durations = durations.reverse()
            return durations;
        },
        goToPayout() {
            this.updateModal ? this.$store.commit('closeModal') : ''
            this.$router.push({ name: 'Payout' } )
        }
    },
    mounted() {
        this.presetForm()

    }
}
</script>

<style lang="scss" scoped>
.input-wrapper {
    select, input, textarea {
        padding: 10px 20px;
    }
}
.cal-picker-wrapper {
    overflow: hidden;
}
</style>