<template>
    <form id="cal_stepper_3_form" @submit.prevent="" class="flx column gap-24">
        <div class="form-row flx jc-sb">
            <div>
                Public or private event?
            </div>
            <div class="flx gap-8 ai-c">
                <label for="public_event" class="flx gap-4" data-type="input-wapper">
                    <input v-model="form.event_type" type="radio" id="public_event" value="public">
                    <span>
                        Public
                    </span>
                </label>
                <label for="private_event" class="flx gap-4" data-type="input-wapper">
                    <input v-model="form.event_type" type="radio" id="private_event" value="private">
                    <span>
                        Private
                    </span>
                </label>
            </div>
        </div>
        <!-- <div class="fs-09 notice" v-if="form.event_type === 'public'">
            <strong>NOTE: </strong>Public trips will automatically be relisted at the same price per person until trip fills up.
        </div> -->
        <div class="form-row column mt-16">
            <label for="attendance_limit">Attendance range</label>

            <div class="slider-container">
                <input type="range" id="minSlider" min="1" max="30" v-model="form.attendance_min" step="1"
                    class="slider">

                <input name="attendance_limit" type="range" id="maxSlider" min="1" max="30" step="1" class="slider"
                    v-model="form.attendance_limit" :class="{ 'gray-bg': editMode === 'event_edit' }">
                <div id="rangeHighlight" class="range-highlight"></div>

                <div class="flx jc-sb new-range-label">
                    <span class="fs-09 gray">Min(1)</span>
                    <span class="bg-white br-16 counter"> <span id="minValue">20</span> - <span id="maxValue">80</span>
                    </span>
                    <span class="fs-09 gray">Max(30)</span>
                </div>
                <span class="input-error" v-if="validation.error && validation.errors.attendance_limit">
                    {{ validation.errors.attendance_limit[0] }}
                </span>
            </div>
            <!-- <input v-model="form.attendance_limit" type="range" min="1" max="30" id="attendance_limit" name="attendance_limit" step="1" class="w-100" :class="{ 'gray-bg' : editMode === 'event_edit'}" />
            <div class="flx jc-sb">
                <span class="fs-09 gray">Min(1)</span>
                <span class="bg-white br-16 counter">{{ form.attendance_limit }}</span>
                <span class="fs-09 gray">Max(30)</span>
            </div>
            <span class="input-error" v-if="validation.error && validation.errors.attendance_limit">
                {{ validation.errors.attendance_limit[0] }}
            </span> -->
        </div>
        <div class="form-row column">
            <!-- <div class="label">Prices(CAD)</div> -->
            <!-- <div class="label">Price{{ form.event_type === 'public' ? ' per person' : 's'}}(CAD)</div> -->
            <!-- <div v-if="form.event_type === 'public'"  class="input-wrapper">
                <input v-model="form.price" @input="checkNumberInput" class="br-16 w-100" type="number" min="1" id="price" name="price"  :class="[{'error-border': validation.errors.price }, input2 ? 'form-control2' : 'form-control']" placeholder="Enter event price" />
            </div> -->
            <div v-if="form.event_type === 'private' || form.event_type === 'public'">
                <div class="fs-09 mb-8">
                    <strong>NOTE: </strong>Amount of persons depends on attendance range.
                </div>

                <div class="flx column gap-4" v-if="this.event_duration_type === 'multi'">
                    <div class="flx jc-sb br-16 ai-c custom-price">
                        <span>Persons</span>
                        <span>{{ this.event_durations[0] }}</span>
                        <span>{{ this.event_durations[1] }}</span>
                    </div>
                    <price-input-day v-for="(price, index) in computedPriceRange" :key="index" :index="index"
                        :label="Number(price)" v-model="form.attendance_min" @add-price-input-day="addPriceInputDay"
                        :prices="form.price_range" />
                </div>
                <div class="flx column gap-4" v-else>
                    <price-input v-for="(price, index) in computedPriceRange" :key="index" :index="index"
                        :label="Number(price)" v-model="form.attendance_min" @add-price-input="addPriceInput"
                        :prices="form.price_range" />
                </div>
            </div>
            <span class="input-error" v-if="validation.error && validation.errors.price">
                {{ validation.errors.price[0] }}
            </span>
        </div>
        <div class="form-row column">
            <label for="event_description">Pricing Information to be<br /> shared with climbers</label>
            <div class="input-wrapper">
                <textarea v-model="form.price_info" class="br-16 w-100" id="price_info" name="price_info"
                    :class="[{ 'error-border': validation.errors.price_info }, input2 ? 'form-control2' : 'form-control']"
                    rows="2"></textarea>
            </div>
            <span class="input-error" v-if="validation.error && validation.errors.price_info">
                {{ validation.errors.price_info[0] }}
            </span>
        </div>
        <!-- Donation Starts here -->
        <div class="form-row flx column gap-2 jc-sb">
            <div>
                <div class="flx gap-8 ai-c">
                    <b>Donations</b>
                    <label for="public_event" class="flx gap-4" data-type="input-wapper">
                        <input v-model="donations_select" type="radio" id="donation_select" value="yes">
                        <span>
                            Yes
                        </span>
                    </label>
                    <label for="private_event" class="flx gap-4" data-type="input-wapper">
                        <input v-model="donations_select" type="radio" id="donation_select" value="no">
                        <span>
                            No
                        </span>
                    </label>
                </div>
            </div>
            <div>
                <span class="fs-09 mt-0">climbers determine prices they donate</span>
            </div>
            <div class="form-row row ai-c add-location mt-6" v-if="donations_select === 'yes'">
                <button class="btn bg-color form-row row ai-c" @click.prevent="donationsAddNewRow">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="18" height="18" rx="9" fill="black" />
                        <path d="M8.5 9.75H4V8.25H8.5V3.75H10V8.25H14.5V9.75H10V14.25H8.5V9.75Z" fill="white" />
                    </svg>
                    <span class="fs-1rem">Add Donations</span>
                </button>
            </div>
            <div class="flx column gap-8">
                <donations-input v-for="(addon, index) in form.donations" :key="addon.id" :index="index" :form="addon"
                    :donations_length="form.donations.length" :input2="input2"
                    @donations-remove-row="donationsRemoveRow" @donations-add-form-input="donationsAddFormInput" />
            </div>
            <span class="input-error" v-if="validation.error && validation.errors.donations">
                {{ validation.errors.donations[0] }}
            </span>
        </div>
        <!-- Donations ends here -->
        <!-- Addons starts here -->
        <div class="form-row flx column gap-2 jc-sb">
            <div>
                <div class="flx gap-8 ai-c">
                    <b>Addons</b>
                    <label for="public_event" class="flx gap-4" data-type="input-wapper">
                        <input v-model="addons_select" type="radio" id="addons_select" value="yes">
                        <span>
                            Yes
                        </span>
                    </label>
                    <label for="private_event" class="flx gap-4" data-type="input-wapper">
                        <input v-model="addons_select" type="radio" id="addons_select" value="no">
                        <span>
                            No
                        </span>
                    </label>
                </div>
            </div>
            <div>
                <span class="fs-09 mt-0">Add a package eg (Photos, Gifts etc)</span>
            </div>
            <div class="form-row row ai-c add-location mt-6" v-if="addons_select === 'yes'">
                <button class="btn bg-color form-row row ai-c" @click.prevent="addonsAddNewRow">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="18" height="18" rx="9" fill="black" />
                        <path d="M8.5 9.75H4V8.25H8.5V3.75H10V8.25H14.5V9.75H10V14.25H8.5V9.75Z" fill="white" />
                    </svg>
                    <span class="fs-1rem">Add Package</span>
                </button>
            </div>
            <div class="flx column gap-8">
                <addons-input v-for="(addon, index) in form.addons" :key="addon.id" :index="index" :form="addon"
                    :addons_length="form.addons.length" :input2="input2" @addons-remove-row="addonsRemoveRow"
                    @addons-add-form-input="addonsAddFormInput" />
            </div>
            <span class="input-error" v-if="validation.error && validation.errors.addons">
                {{ validation.errors.addons[0] }}
            </span>
        </div>
        <!-- Addons ends here -->
        <div class="form-row column">
            <label for="event_description">Event description</label>
            <div class="input-wrapper">
                <textarea v-model="form.event_description" class="br-16 w-100" id="event_description"
                    name="event_description"
                    :class="[{ 'error-border': validation.errors.event_description }, input2 ? 'form-control2' : 'form-control']"
                    rows="2"></textarea>
            </div>
            <span class="input-error" v-if="validation.error && validation.errors.event_description">
                {{ validation.errors.event_description[0] }}
            </span>
        </div>

        <error-display-card v-if="validation.error" :errors="validation.errors" />
        <div class="flx column gap-8 calendar-btn-wrapper">
            <button @click.prevent="nextPage" class="button-primary btn-lg w-100">Next</button>
            <button @click.prevent="previousPage"
                class="bg-transparent btn-sm w-100">Back</button>
        </div>
    </form>
</template>

<script>
import inputValidationMixin from '@/mixins/inputValidation'
import alertMixin from '@/mixins/alertMixin'
import PriceInput from './PriceInput.vue'
import PriceInputDay from './PriceInputDay.vue'
import AddonsInput from './AddonsInput.vue'
import DonationsInput from './DonationsInput.vue'
import ErrorDisplayCard from './ErrorDisplayCard.vue'
export default {
    components: { PriceInput, PriceInputDay, ErrorDisplayCard, AddonsInput, DonationsInput },
    name: 'CalendarStepper3',
    mixins: [inputValidationMixin, alertMixin],
    props: {
        newEvent: Object,
        input2: Boolean,
        editMode: String
    },
    computed: {
        computedPriceRange() {
            // Generate an array from start to end
            const computedarray = this.createArray(Number(this.form.attendance_min), this.form.attendance_limit)

            return computedarray;

        }

    },
    data() {
        return {
            form: {
                event_type: 'public',
                attendance_limit: '4',
                attendance_min: 1,
                limit_count: 0,
                price: '',
                price_range: [],
                price_info: '',
                addons: [],
                donations: [],
                event_description: ''
            },
            donations_select: 'yes',
            addons_select: 'yes',
            event_duration_type: '',
            event_durations: [],
        }
    },
    updated() {
        // console.log(this.addons_select);
        // console.log(this.form.addons);
        // console.log(this.donations_select);
        // console.log(this.form.donations);
        // do something here


    },
    watch: {
        addons_select(newVal) {
            if (newVal === "no") {
                this.form.addons = []
            }
        },
        donations_select(newVal) {
            if (newVal === "no") {
                this.form.donations = []
            }
        },
        'form.price_range'(newVal) {
            console.log(newVal)
        }
    },
    methods: {
        addonsAddNewRow() {
            const data = { id: new Date().getTime(), addons_title: '', addons_price: '' }
            this.form.addons.push(data)
        },
        addonsRemoveRow(index) {
            const i = index
            this.form.addons.splice(i, 1);
        },
        addonsAddFormInput(payload) {
            const i = this.form.addons.findIndex(x => x.id == payload.id)
            this.form.addons.splice(i, 1, payload)
        },
        donationsAddNewRow() {
            const data = { id: new Date().getTime(), donations_title: '', donations_price: '' }
            this.form.donations.push(data)
        },
        donationsRemoveRow(index) {
            const i = index
            this.form.donations.splice(i, 1);
        },
        donationsAddFormInput(payload) {
            const i = this.form.donations.findIndex(x => x.id == payload.id)
            this.form.donations.splice(i, 1, payload)
        },
        addPriceInput(payload) {
            const i = this.form.price_range.findIndex(x => x.id === payload.id)
            if (i > -1) {
                this.form.price_range.splice(i, 1, payload)
                console.log(this.form.price_range)
            } else {
                this.form.price_range.push(payload)
            }
        },
        addPriceInputDay(payload) {
            const i = this.form.price_range.findIndex(x => x.id === payload.id)
            if (i > -1) {
                this.form.price_range.splice(i, 1, payload)
                console.log(this.form.price_range)
            } else {
                this.form.price_range.push(payload)
            }
        },
        validateAddons() {
            const addons = this.form.addons;
            return addons.every(addon => addon.addons_title && addon.addons_price && addon.addons_title !== '' && addon.addons_price !== '');
        },
        validateDonations() {
            const donations = this.form.donations;
            return donations.every(addon => addon.donations_title && addon.donations_title !== '');
        },
        createArray(min, max) {
            let arr = [];
            for (let i = min; i <= max; i++) {
                arr.push(i);
            }
            return arr;
        },
        async nextPage() {
            this.validation.error ? this.clearErrs() : ''
            let errors = {}

            const attendance_count = this.createArray(this.form.attendance_min, this.form.attendance_limit).length
            if (this.form.event_type === 'private' || this.form.event_type === 'public') {
                this.form.price_range = this.form.price_range.slice(0, attendance_count)
            }

            if (((this.form.event_type === 'private' || this.form.event_type === 'public') && (!this.validatePriceRange() || attendance_count != this.form.price_range.length)) || !this.validateAddons() || !this.validateDonations()) {
                if (this.form.price === '') {
                    errors.price = ['The price field is required.']
                }
                if (!this.validatePriceRange() || attendance_count != this.form.price_range.length) {
                    errors.price = ['Please specify the price for all persons.']
                }
                if (!this.validateAddons()) {
                    errors.addons = ['Please fill out all the Addons field.']
                }
                if (!this.validateDonations()) {
                    errors.donations = ['Please fill out the title of the donations field.']
                }
                this.showErr(errors)
            } else {
                this.editMode === 'event_edit' ? await this.$store.commit('updateTempStorage3', this.form) : await this.$store.commit('saveEventForm3', this.form)
                this.$router.push({ query: { stepper: '4', current: this.$route.query.current, origin: this.$route.query.origin } })
            }
        },
        validatePriceRange() {
            if (this.event_duration_type === 'multi') {
                return this.form.price_range.every(item => item.price && item.price !== undefined && item.price !== '' && item.price !== null && item.priceh && item.priceh !== undefined && item.priceh !== '' && item.priceh !== null)
            } else {
                return this.form.price_range.every(item => item.price && item.price !== undefined && item.price !== '' && item.price !== null)
            }

        },
        previousPage() {
            this.$router.push({ query: { stepper: '2', current: this.$route.query.current, origin: this.$route.query.origin } })
        },

        presetForm() {
            if (this.newEvent) {
                this.newEvent.event_type ? this.form.event_type = this.newEvent.event_type : ''
                this.newEvent.attendance_limit ? this.form.attendance_limit = this.newEvent.attendance_limit : ''
                this.newEvent.attendance_min ? this.form.attendance_min = this.newEvent.attendance_min : ''

                //check if event_duration is multiple or single

                if (this.newEvent && this.newEvent.event_duration && this.newEvent.event_duration.length == 2) {
                    this.event_durations = this.newEvent.event_duration;
                    this.event_duration_type = 'multi'
                } else if (this.newEvent && this.newEvent.event_duration && this.newEvent.event_duration.length == 1) {
                    this.event_durations = this.newEvent.event_duration;
                    this.event_duration_type = 'single'
                }
                //preset adons and donations
                if (this.editMode === 'event_edit') {
                    this.newEvent.addons ? this.form.addons = JSON.parse(this.newEvent.addons) : ''
                    this.newEvent.donations ? this.form.donations = JSON.parse(this.newEvent.donations) : ''
                } else {
                    this.newEvent.addons ? this.form.addons = this.newEvent.addons : ''
                    this.newEvent.donations ? this.form.donations = this.newEvent.donations : ''
                }
                //preset price info
                this.newEvent.price_info ? this.form.price_info = this.newEvent.price_info : ''


                this.newEvent.event_description ? this.form.event_description = this.newEvent.event_description : ''
                if (this.editMode === 'event_edit') {
                    if (this.newEvent.event_type === 'private') {
                        // this.form.price_range = [JSON.parse(this.newEvent.price)]
                        this.form.price_range = JSON.parse(this.newEvent.price)
                    } else {
                        this.form.price_range = JSON.parse(this.newEvent.price)
                    }
                    console.log(this.form.price_range)
                } else {
                    this.newEvent.price ? this.form.price = this.newEvent.price : ''
                    // console.log(this.newEvent.price_range)
                    this.newEvent.price_range ? this.form.price_range = this.newEvent.price_range : ''
                }

                if (this.editMode === 'event_edit' && this.newEvent.event_duration && this.getdurations(this.newEvent.event_duration).length == 2) {
                    this.event_duration_type = 'multi'
                     //set the values of this.form.event_duration to the values of this.newEvent.event_duration
                    this.event_durations = this.getdurations(this.newEvent.event_duration)
                } else if (this.editMode === 'event_edit' && this.getdurations(this.newEvent.event_duration).length == 1) {
                    this.event_duration_type = 'single'
                    this.event_durations = this.getdurations(this.newEvent.event_duration)[0]
                }
            }
        },
        getdurations(durations) {
            if(/^\[.*\]$/.test(durations)){
                durations = durations.replace('[', '').replace(']', '').replace(/"/g, '').split(',')
                // durationarray = [this.event.event_duration.replace('[', '').replace(']', '').replace('"', '').replace(/"/g, '')]
            }else{
                durations = [durations]
            }
            return durations;
        }
    },
    mounted() {
        this.presetForm()
        //my code
        const minSlider = document.getElementById('minSlider');
        const maxSlider = document.getElementById('maxSlider');
        const minValueDisplay = document.getElementById('minValue');
        const maxValueDisplay = document.getElementById('maxValue');
        const rangeHighlight = document.getElementById('rangeHighlight');

        minSlider.value = this.form.attendance_min
        maxSlider.value = this.form.attendance_limit
        // Function to update the slider's visual appearance
        function updateRange() {
            const min = parseInt(minSlider.value);
            const max = parseInt(maxSlider.value);

            if (min >= max) {
                minSlider.value = max - 1;  // Prevent overlap
            }

            if (max <= min) {
                maxSlider.value = min + 1;  // Prevent overlap
            }

            // Update the display of the min and max values
            minValueDisplay.textContent = minSlider.value;
            maxValueDisplay.textContent = maxSlider.value;

            // Update the position and width of the range highlight
            const percentMin = (minSlider.value / minSlider.max) * 100;
            const percentMax = (maxSlider.value / maxSlider.max) * 100;
            rangeHighlight.style.left = percentMin + '%';
            rangeHighlight.style.width = (percentMax - percentMin) + '%';
        }

        // Attach event listeners to sliders
        minSlider.addEventListener('input', updateRange);
        maxSlider.addEventListener('input', updateRange);

        // Initial update to set the range highlight
        updateRange();

        //my code
    }
}
</script>


<style lang="scss" scoped>
.input-wrapper {

    select,
    input,
    textarea {
        padding: 10px 20px
    }
}

.counter {
    padding: 2px 16px;
}

.notice {
    padding-bottom: 20px;
    border-bottom: 1px solid #d9d9d9;
}

.gray-bg.gray-bg {
    background-color: #F1F1F1;
}

.slider-container {
    position: relative;
    width: 100%;
    height: 50px;

    .slider {
        -webkit-appearance: none;
        appearance: none;
        width: 100%;
        height: 5px;
        background: #ffffff;
        pointer-events: none;
        /* Disable interaction directly on this slider */
        position: absolute;
        top: 5px;
    }

    .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 20px;
        height: 20px;
        background-color: #007bff !important;
        border-radius: 50%;
        cursor: pointer;
        pointer-events: auto;
        /* Enable interaction */
        position: relative;
        z-index: 2;
    }

    .slider::-moz-range-thumb {
        width: 20px;
        height: 20px;
        background-color: #007bff !important;
        border-radius: 50%;
        cursor: pointer;
        pointer-events: auto;
        position: relative;
        z-index: 2;
    }

    /* Visual range highlight */
    .range-highlight {
        position: absolute;
        background-color: #007bff;
        height: 5px;
        z-index: 1;
        top: 7px;
        border-radius: 5px;
    }

    .new-range-label {
        margin-top: 20px;
    }
}






.value-display {
    margin-top: 20px;
}
</style>